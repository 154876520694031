import React, { useState, useMemo } from 'react';

const GuildMember = [
  {
    startDate: '10 Dec 2023',
    username: 'Anaxagoras',
    mainCharacterName: 'Anaxagoras',
    email: 'Anaxagoras@nivirum.club',
    imageSrc: '/Information/logoanaxagoras.png',
    memberGuildRole: 'Gerente Senior',
    memberFamilyRole: 'Member',
    memberMainArchetype: 'Bard',
    memberSecondaryArchetype: 'Tank',
    status: { text: 'Active', bgColor: 'bg-green-500/20', textColor: 'text-green-600' },
    lastActivity: '23/04/18',
    lvl: '25',
    ilvl: '136',
    dkp_g: '234',
    dkp_s: '469'
  },
  {
    startDate: '10 Dec 2023',
    username: 'Crosis',
    mainCharacterName: 'Crocodile',
    email: 'Crosis@nivirum.club',
    imageSrc: '/Information/logocrosis.jpg',
    memberGuildRole: 'Líder',
    memberFamilyRole: 'Líder',
    memberMainArchetype: 'Tank',
    memberSecondaryArchetype: 'Fighter',
    status: { text: 'Holiday', bgColor: 'bg-purple-500/20', textColor: 'text-purple-600' },
    lastActivity: '23/04/18',
    lvl: '25',
    ilvl: '136',
    dkp_g: '234',
    dkp_s: '469'
  },
  {
    startDate: '10 Dec 2023',
    username: 'Sherkus',
    mainCharacterName: 'Sher',
    email: 'Sherkus@nivirum.club',
    imageSrc: '/Information/logosherkus.png',
    memberGuildRole: 'Gerente Senior',
    memberFamilyRole: 'Caller',
    memberMainArchetype: 'Fighter',
    memberSecondaryArchetype: 'Bard',
    status: { text: 'Inactive', bgColor: 'bg-yellow-500/20', textColor: 'text-yellow-600' },
    lastActivity: '19/09/17',
    lvl: '25',
    ilvl: '136',
    dkp_g: '234',
    dkp_s: '469'
  },
  {
    startDate: '10 Dec 2023',
    username: 'Vyro',
    mainCharacterName: 'Vyyyro',
    email: 'Vyyyro@nivirum.club',
    imageSrc: '/Information/logovyro.png',
    memberGuildRole: 'Gerente',
    memberFamilyRole: 'Caller',
    memberMainArchetype: 'Cleric',
    memberSecondaryArchetype: 'Bard',
    status: { text: 'Active', bgColor: 'bg-green-500/20', textColor: 'text-green-600' },
    lastActivity: '24/12/08',
    lvl: '25',
    ilvl: '136',
    dkp_g: '234',
    dkp_s: '469'
  },
  {
    startDate: '10 Dec 2023',
    username: 'Sark',
    mainCharacterName: 'SHARKñamñam',
    email: 'Sark@nivirum.club',
    imageSrc: '/Information/logosark.png',
    memberGuildRole: 'Alpha 2',
    memberFamilyRole: 'Oficial',
    memberMainArchetype: 'Bard',
    memberSecondaryArchetype: 'Cleric',
    status: { text: 'Active', bgColor: 'bg-green-500/20', textColor: 'text-green-600' },
    lastActivity: '04/10/21',
    lvl: '25',
    ilvl: '136',
    dkp_g: '234',
    dkp_s: '469'
  },
];

const GuildManagement: React.FC = () => {

  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' | 'none' }>({ key: '', direction: 'none' });

  const handleSort = (key: string) => {
    // Si se cambia de columna, iniciar en asc
    if (sortConfig.key !== key) {
      setSortConfig({ key, direction: 'asc' });
    } else {
      // Si es la misma columna, ciclar asc -> desc -> none -> asc...
      if (sortConfig.direction === 'asc') {
        setSortConfig({ key, direction: 'desc' });
      } else if (sortConfig.direction === 'desc') {
        setSortConfig({ key: '', direction: 'none' });
      } else {
        // direction === 'none'
        setSortConfig({ key, direction: 'asc' });
      }
    }
  };

  // Esta función obtiene el valor según la key para cada miembro
  const getValue = (member: any, key: string): string => {
    switch (key) {
      case 'username':
        return member.username;
      case 'memberGuildRole':
        return member.memberGuildRole;
      case 'memberMainArchetype':
        return member.memberMainArchetype;
      case 'lvl':
        return member.lvl;
      case 'dkp_g':
        return member.dkp_g;
      case 'dkp_s':
        return member.dkp_s;
      case 'lastActivity':
        return member.lastActivity;
      case 'status':
        return member.status.text;
      default:
        return '';
    }
  };

  const sortedMembers = useMemo(() => {
    if (sortConfig.direction === 'none' || sortConfig.key === '') {
      return GuildMember;
    }

    const sorted = [...GuildMember].sort((a, b) => {
      const aVal = getValue(a, sortConfig.key);
      const bVal = getValue(b, sortConfig.key);

      // Intentamos parsear como número, si no se puede, comparamos como string
      const aNum = parseFloat(aVal);
      const bNum = parseFloat(bVal);
      if (!isNaN(aNum) && !isNaN(bNum)) {
        return sortConfig.direction === 'asc' ? aNum - bNum : bNum - aNum;
      } else {
        return sortConfig.direction === 'asc'
          ? aVal.localeCompare(bVal)
          : bVal.localeCompare(aVal);
      }
    });

    return sorted;
  }, [sortConfig]);

  const getSortIcon = (key: string) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="h-4 w-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="h-4 w-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9L12 5.25 8.25 9m7.5 6L12 18.75 8.25 15"></path>
        </svg>
      );
    }
    return null;
  };

  const getSortArrowClass = (key: string) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? 'inline-block ml-2 text-black' : 'inline-block ml-2 text-black rotate-180';
    }
    return 'inline-block ml-2 text-gray-400';
  };

  return (
    <div className="p-32 overflow-hidden px-4">
      <table className="mt-4 w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {/* Member */}
            <th onClick={() => handleSort('username')} className="cursor-pointer border-y border-yellow-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
              <p className="antialiased font-sans text-sm text-white-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                Member
                {getSortIcon('username')}
              </p>
            </th>
            {/* Member */}
            <th onClick={() => handleSort('username')} className="cursor-pointer border-y border-yellow-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
              <p className="antialiased font-sans text-sm text-white-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                Main Character Name
                {getSortIcon('username')}
              </p>
            </th>
            {/* Guild Role */}
            <th onClick={() => handleSort('memberGuildRole')} className="cursor-pointer border-y border-yellow-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
              <p className="antialiased font-sans text-sm text-white-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                Guild Role
                {getSortIcon('memberGuildRole')}
              </p>
            </th>
            {/* Archetypes */}
            <th onClick={() => handleSort('memberMainArchetype')} className="cursor-pointer border-y border-yellow-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
              <p className="antialiased font-sans text-sm text-white-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                Archetypes
                {getSortIcon('memberMainArchetype')}
              </p>
            </th>
            {/* Level e Ilvl */}
            <th onClick={() => handleSort('lvl')} className="cursor-pointer border-y border-yellow-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
              <p className="antialiased font-sans text-sm text-white-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                Level e Ilvl
                {getSortIcon('lvl')}
              </p>
            </th>
            {/* DKP_G */}
            <th onClick={() => handleSort('dkp_g')} className="cursor-pointer border-y border-yellow-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
              <p className="antialiased font-sans text-sm text-white-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                DKP_G
                {getSortIcon('dkp_g')}
              </p>
            </th>
            {/* DKP_S */}
            <th onClick={() => handleSort('dkp_s')} className="cursor-pointer border-y border-yellow-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
              <p className="antialiased font-sans text-sm text-white-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                DKP_S
                {getSortIcon('dkp_s')}
              </p>
            </th>
            {/* Last Activity */}
            <th onClick={() => handleSort('lastActivity')} className="cursor-pointer border-y border-yellow-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
              <p className="antialiased font-sans text-sm text-white-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                Last Activity
                {getSortIcon('lastActivity')}
              </p>
            </th>
            {/* Status */}
            <th onClick={() => handleSort('status')} className="cursor-pointer border-y border-yellow-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
              <p className="antialiased font-sans text-sm text-white-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                Status
                {getSortIcon('status')}
              </p>
            </th>
            {/* Actions (sin orden) */}
            <th className="cursor-pointer border-y border-yellow-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
              <p className="antialiased font-sans text-sm text-white-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                Actions
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedMembers.map((member, index) => (
            <tr key={index}>

              {/* Member */}
              <td className="p-4 border-b border-yellow-50">
                <div className="flex items-center gap-3">
                  <img
                    src={member.imageSrc}
                    alt={member.username}
                    className="inline-block relative object-cover object-center !rounded-full w-9 h-9 rounded-md"
                  />
                  <div className="flex flex-col">
                    <p className="block antialiased font-sans text-sm leading-normal text-white-900 font-normal">
                      {member.username}
                    </p>
                    <p className="block antialiased font-sans text-sm leading-normal text-white-900 font-normal opacity-70">
                      {member.email}
                    </p>
                  </div>
                </div>
              </td>

              {/* Main Character Name */}
              <td className="p-4 border-b border-yellow-50">
                <p className="block antialiased font-sans text-sm leading-normal text-white-900 font-normal">
                  {member.mainCharacterName}
                </p>
              </td>

              {/* Guild Role */}
              <td className="p-4 border-b border-yellow-50">
                <div className="flex flex-col">
                  <p className="block antialiased font-sans text-sm leading-normal text-white-900 font-normal">
                    {member.memberGuildRole}
                  </p>
                  <p className="block antialiased font-sans text-sm leading-normal text-white-900 font-normal opacity-70">
                    {member.memberFamilyRole}
                  </p>
                </div>
              </td>

              {/* Archetypes */}
              <td className="p-4 border-b border-yellow-50">
                <div className="flex flex-col">
                  <p className="block antialiased font-sans text-sm leading-normal text-white-900 font-normal">
                    {member.memberMainArchetype}
                  </p>
                  <p className="block antialiased font-sans text-sm leading-normal text-white-900 font-normal opacity-70">
                    {member.memberSecondaryArchetype}
                  </p>
                </div>
              </td>

              {/* Level e Ilvl */}
              <td className="p-4 border-b border-yellow-50">
                <div className="flex flex-col">
                  <p className="block antialiased font-sans text-sm leading-normal text-white-900 font-normal">
                    {member.lvl}
                  </p>
                  <p className="block antialiased font-sans text-sm leading-normal text-white-900 font-normal opacity-70">
                    {member.ilvl}
                  </p>
                </div>
              </td>

              {/* DKP_G */}
              <td className="p-4 border-b border-yellow-50">
                <p className="block antialiased font-sans text-sm leading-normal text-white-900 font-normal">
                  {member.dkp_g}
                </p>
              </td>

              {/* DKP_S */}
              <td className="p-4 border-b border-yellow-50">
                <p className="block antialiased font-sans text-sm leading-normal text-white-900 font-normal">
                  {member.dkp_s}
                </p>
              </td>

              {/* Last Activity */}
              <td className="p-4 border-b border-yellow-50">
                <p className="block antialiased font-sans text-sm leading-normal text-white-900 font-normal">
                  {member.lastActivity}
                </p>
              </td>

              {/* Status */}
              <td className="p-4 border-b border-yellow-50">
                <div className="w-max">
                  <div
                    className={`relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none ${member.status.bgColor} ${member.status.textColor} py-1 px-2 text-xs rounded-md opacity-100`}
                  >
                    <span className="">{member.status.text}</span>
                  </div>
                </div>
              </td>

              {/* Actions */}
              <td className="p-4 border-b border-yellow-50">
                <button
                  className="relative align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-white-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30"
                  type="button"
                >
                  <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 6.75a4.5 4.5 0 100 9 4.5 4.5 0 000-9zM20.25 20.25l-4.5-4.5"
                      />
                    </svg>
                  </span>
                </button>
              </td>

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GuildManagement;
