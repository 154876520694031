import React from "react";

const GuildCalendar: React.FC = () => {
    return (
        <div className="relative min-h-screen">
            {/* Contenedor principal con margen superior */}
            <div className="pt-32 px-4 sm:px-6 md:px-12 lg:px-24">
                <div className="lg:flex lg:h-full lg:flex-col">
                    <header className="flex items-center justify-between border-b border-[#ffdd57] px-6 py-4 lg:flex-none">
                        <h1 className="text-base font-bold leading-6 text-white text-xl">
                            <time dateTime="2022-01">DECEMBER 2024</time>
                        </h1>
                        <div className="flex items-center">
                            <div className="hidden md:ml-4 md:flex md:items-center">
                                <button type="button" className="ml-6 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500">Add event</button>
                                <button type="button" className="ml-6 rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500">Stop event</button>
                                <button type="button" className="ml-6 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500">Cancel event</button>
                            </div>
                        </div>
                    </header>
                    <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                        <div className="grid grid-cols-7 gap-px border-b border-[#ffdd57] bg-yellow-400 text-center text-s font-bold leading-6 text-white lg:flex-none">
                            <div className="flex justify-center bg-[#1e1e1e] py-2">
                                <span>M</span>
                                <span className="sr-only sm:not-sr-only">on</span>
                            </div>
                            <div className="flex justify-center bg-[#1e1e1e] py-2">
                                <span>T</span>
                                <span className="sr-only sm:not-sr-only">ue</span>
                            </div>
                            <div className="flex justify-center bg-[#1e1e1e] py-2">
                                <span>W</span>
                                <span className="sr-only sm:not-sr-only">ed</span>
                            </div>
                            <div className="flex justify-center bg-[#1e1e1e] py-2">
                                <span>T</span>
                                <span className="sr-only sm:not-sr-only">hu</span>
                            </div>
                            <div className="flex justify-center bg-[#1e1e1e] py-2">
                                <span>F</span>
                                <span className="sr-only sm:not-sr-only">ri</span>
                            </div>
                            <div className="flex justify-center bg-[#1e1e1e] py-2">
                                <span>S</span>
                                <span className="sr-only sm:not-sr-only">at</span>
                            </div>
                            <div className="flex justify-center bg-[#1e1e1e] py-2">
                                <span>S</span>
                                <span className="sr-only sm:not-sr-only">un</span>
                            </div>
                        </div>
                        
                        <div className="flex bg-yellow-400 text-xs leading-6 text-white lg:flex-auto">
                            <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                                <div className="relative bg-[#3d3d3d] px-3 py-2 text-white">
                                    <time dateTime="2021-12-27">27</time>
                                </div>
                                <div className="relative bg-[#3d3d3d] px-3 py-2 text-white">
                                    <time dateTime="2021-12-28">28</time>
                                </div>
                                <div className="relative bg-[#3d3d3d] px-3 py-2 text-white">
                                    <time dateTime="2021-12-29">29</time>
                                </div>
                                <div className="relative bg-[#3d3d3d] px-3 py-2 text-white">
                                    <time dateTime="2021-12-30">30</time>
                                </div>
                                <div className="relative bg-[#3d3d3d] px-3 py-2 text-white">
                                    <time dateTime="2021-12-31">31</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-01">1</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-01">2</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-03">3</time>
                                    <ol className="mt-2">
                                        <li>
                                            <a href="#" className="group flex">
                                                <p className="flex-auto truncate font-medium text-white group-hover:text-yellow-400">PROFESSION GUILD EVENT</p>
                                                <time dateTime="2022-01-03T10:00" className="ml-3 hidden flex-none text-white group-hover:text-yellow-400 xl:block">5PM</time>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="group flex">
                                                <p className="flex-auto truncate font-medium text-white group-hover:text-yellow-400">TUMOK</p>
                                                <time dateTime="2022-01-03T14:00" className="ml-3 hidden flex-none text-white group-hover:text-yellow-400 xl:block">7PM</time>
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-04">4</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-05">5</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-06">6</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-07">7</time>
                                    <ol className="mt-2">
                                        <li>
                                            <a href="#" className="group flex">
                                                <p className="flex-auto truncate font-medium text-white group-hover:text-yellow-400">NODE SIEGE</p>
                                                <time dateTime="2022-01-08T18:00" className="ml-3 hidden flex-none text-white group-hover:text-yellow-400 xl:block">8PM</time>
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-08">8</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-09">9</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-10">10</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-11">11</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-12" className="flex h-6 w-6 items-center justify-center rounded-full bg-yellow-400 font-semibold text-black">12</time>
                                    <ol className="mt-2">
                                        <li>
                                            <a href="#" className="group flex">
                                                <p className="flex-auto truncate font-medium text-white group-hover:text-yellow-400">TUMOK</p>
                                                <time dateTime="2022-01-25T14:00" className="ml-3 hidden flex-none text-white group-hover:text-yellow-400 xl:block">8PM</time>
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-13">13</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-14">14</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-15">15</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-16">16</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-17">17</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-18">18</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-19">19</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-20">20</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-21">21</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-22">22</time>
                                    <ol className="mt-2">
                                        <li>
                                            <a href="#" className="group flex">
                                                <p className="flex-auto truncate font-medium text-white group-hover:text-yellow-400">PROFESSION GUILD EVENT</p>
                                                <time dateTime="2022-01-22T15:00" className="ml-3 hidden flex-none text-white group-hover:text-yellow-400 xl:block">5PM</time>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="group flex">
                                                <p className="flex-auto truncate font-medium text-white group-hover:text-yellow-400">CARPHIN</p>
                                                <time dateTime="2022-01-22T19:00" className="ml-3 hidden flex-none text-white group-hover:text-yellow-400 xl:block">7PM</time>
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-23">23</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-24">24</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-25">25</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-26">26</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-27">27</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-28">28</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-29">29</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-30">30</time>
                                </div>
                                <div className="relative bg-[#1e1e1e] px-3 py-2">
                                    <time dateTime="2022-01-31">31</time>
                                </div>
                                <div className="relative bg-[#3d3d3d] px-3 py-2 text-white">
                                    <time dateTime="2022-02-01">1</time>
                                </div>
                                <div className="relative bg-[#3d3d3d] px-3 py-2 text-white">
                                    <time dateTime="2022-02-02">2</time>
                                </div>
                                <div className="relative bg-[#3d3d3d] px-3 py-2 text-white">
                                    <time dateTime="2022-02-03">3</time>
                                </div>
                                <div className="relative bg-[#3d3d3d] px-3 py-2 text-white">
                                    <time dateTime="2022-02-04">4</time>
                                    <ol className="mt-2">
                                        <li>
                                            <a href="#" className="group flex">
                                                <p className="flex-auto truncate font-medium text-white group-hover:text-yellow-400">NODE SIEGE</p>
                                                <time dateTime="2022-02-04T21:00" className="ml-3 hidden flex-none text-white group-hover:text-yellow-400 xl:block">8PM</time>
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="relative bg-[#3d3d3d] px-3 py-2 text-white">
                                    <time dateTime="2022-02-05">5</time>
                                </div>
                                <div className="relative bg-[#3d3d3d] px-3 py-2 text-white">
                                    <time dateTime="2022-02-06">6</time>
                                </div>
                            </div>
                            <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                                <button type="button" className="flex h-14 flex-col bg-[#3d3d3d] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2021-12-27" className="ml-auto">27</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#3d3d3d] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2021-12-28" className="ml-auto">28</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#3d3d3d] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2021-12-29" className="ml-auto">29</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#3d3d3d] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2021-12-30" className="ml-auto">30</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#3d3d3d] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2021-12-31" className="ml-auto">31</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-01" className="ml-auto">1</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-02" className="ml-auto">2</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-03" className="ml-auto">3</time>
                                    <span className="sr-only">2 events</span>
                                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                        <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-yellow-400"></span>
                                        <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-yellow-400"></span>
                                    </span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-04" className="ml-auto">4</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-05" className="ml-auto">5</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-06" className="ml-auto">6</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-07" className="ml-auto">7</time>
                                    <span className="sr-only">1 event</span>
                                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                        <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-yellow-400"></span>
                                    </span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-08" className="ml-auto">8</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-09" className="ml-auto">9</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-10" className="ml-auto">10</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-11" className="ml-auto">11</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 font-semibold text-yellow-600 hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-12" className="ml-auto">12</time>
                                    <span className="sr-only">1 event</span>
                                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                        <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-yellow-400"></span>
                                    </span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-13" className="ml-auto">13</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-14" className="ml-auto">14</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-15" className="ml-auto">15</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-16" className="ml-auto">16</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-17" className="ml-auto">17</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-18" className="ml-auto">18</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-19" className="ml-auto">19</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-20" className="ml-auto">20</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-21" className="ml-auto">21</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 font-semibold text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-22" className="ml-auto flex h-6 w-6 items-center justify-center rounded-full bg-yellow-400">22</time>
                                    <span className="sr-only">2 events</span>
                                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                        <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-yellow-400"></span>
                                        <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-yellow-400"></span>
                                    </span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-23" className="ml-auto">23</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-24" className="ml-auto">24</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-25" className="ml-auto">25</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-26" className="ml-auto">26</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-27" className="ml-auto">27</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-28" className="ml-auto">28</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-29" className="ml-auto">29</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-30" className="ml-auto">30</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#1e1e1e] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-01-31" className="ml-auto">31</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#3d3d3d] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-02-01" className="ml-auto">1</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#3d3d3d] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-02-02" className="ml-auto">2</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#3d3d3d] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-02-03" className="ml-auto">3</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#3d3d3d] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-02-04" className="ml-auto">4</time>
                                    <span className="sr-only">1 event</span>
                                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                        <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-yellow-400"></span>
                                    </span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#3d3d3d] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-02-05" className="ml-auto">5</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                                <button type="button" className="flex h-14 flex-col bg-[#3d3d3d] px-3 py-2 text-white hover:bg-yellow-400 focus:z-10">
                                    <time dateTime="2022-02-06" className="ml-auto">6</time>
                                    <span className="sr-only">0 events</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GuildCalendar;