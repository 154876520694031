import React from 'react';

const logoSrc = './logo.png'; // Define the logoSrc variable

const Logo: React.FC = () => {
  return (
    <div className="flex items-center space-x-4">
      <a href="/" className="flex items-center" aria-label="Home">
        <img src="/Logo_version_2-transformed.png" alt="Logo" className="h-12" />
      </a>
    </div>
  );
};

export default Logo;
