import React, { useState } from "react";

// Datos para la tabla Build


let currentTooltipHeight = 0;



const Profile: React.FC = () => {

    const profile_data = {
        build_id: 1,
        member_id: "Antiquericard",
        ilvl: 83,
        main_archetype: "BARD",
        secondary_archetype: "CLERIC",
        guild_role: "Gerente Senior",
        family: "Rohirrim",
        squadron: "Squad One",
        squadron_role: "Caller",
        dkp_squadron: 87,
        dkp_guild: 726,
        level: 25,
        items: [
            { item_id: 1, name: "Head", DKP_Value: 6, href: "https://ashescodex.com/db/item/Gear_Armor_Heavy_AcademusMonitor_Head" },
            { item_id: 2, name: "Shoulders", DKP_Value: 3, href: "https://ashescodex.com/db/item/Gear_Armor_Medium_Bloodrunner_Shoulders?rarity=3" },
            { item_id: 3, name: "Back", DKP_Value: 2, href: "https://ashescodex.com/db/item/Gear_Armor_Light_Corvus_Back?rarity=2" },
            { item_id: 4, name: "Chest", DKP_Value: 6, href: "https://ashescodex.com/db/item/Gear_Armor_Medium_Bloodrunner_Chest?rarity=6" },
            { item_id: 5, name: "Forearms", DKP_Value: 3, href: "https://ashescodex.com/db/item/Gear_Armor_Heavy_CataphractOfValor_Forearms?rarity=3" },
            { item_id: 6, name: "Hands", DKP_Value: 3, href: "https://ashescodex.com/db/item/Gear_Armor_Light_BloomWarden_Hands?rarity=3" },
            { item_id: 7, name: "Waist", DKP_Value: 3, href: "https://ashescodex.com/db/item/Gear_Armor_Medium_BigBro_Waist?rarity=3" },
            { item_id: 8, name: "Legs", DKP_Value: 3, href: "https://ashescodex.com/db/item/Gear_Armor_Heavy_AcademusMonitor_Legs?rarity=3" },
            { item_id: 9, name: "Feet", DKP_Value: 3, href: "https://ashescodex.com/db/item/Gear_Armor_Medium_Bloodrunner_Feet?rarity=3" },
            { item_id: 10, name: "Earring", DKP_Value: 3, href: "https://ashescodex.com/db/item/Gear_Accessory_Earring_Historian?rarity=3" },
            { item_id: 11, name: "Earring", DKP_Value: 3, href: "https://ashescodex.com/db/item/Gear_Accessory_Earring_Iron?rarity=3" },
            { item_id: 12, name: "Necklace", DKP_Value: 3, href: "https://ashescodex.com/db/item/Gear_Accessory_Necklace_Carphin?rarity=3" },
            { item_id: 13, name: "Ring 1", DKP_Value: 3, href: "https://ashescodex.com/db/item/Gear_Accessory_Ring_Bloodied?rarity=3" },
            { item_id: 14, name: "Ring 2", DKP_Value: 3, href: "https://ashescodex.com/db/item/Gear_Accessory_Ring_Lumadon?rarity=3" },
            { item_id: 15, name: "PrimaryMelee", DKP_Value: 3, href: "https://ashescodex.com/db/item/Gear_Weapon_Sword_2H_Viscera?rarity=3" },
            { item_id: 16, name: "SecondaryMelee", DKP_Value: 0, href: "" },
            { item_id: 15, name: "PrimaryRange", DKP_Value: 3, href: "https://ashescodex.com/db/item/Gear_Weapon_Longbow_2H_Firebrand?rarity=4" },
            { item_id: 16, name: "SecondaryRange", DKP_Value: 0, href: "" }
        ],
        build_url: "https://ashescodex.com/planner/share/240b27a4-69d4-417e-a6be-cc2fabe3ef12",
        primary_stats: [
            { name: "Strength", value: 42 },
            { name: "Dexterity", value: 40 },
            { name: "Constitution", value: 83 },
            { name: "Intelligence", value: 98 },
            { name: "Wisdom", value: 87 },
            { name: "Mentality", value: 70 },
        ],
        secondary_stats: [
            {
                title: "Healing and Regeneration",
                stats: [
                    { name: "Health Regeneration", value: "4.3" },
                    { name: "Mana Regeneration", value: "5.4" },
                    { name: "Healing Power", value: "360.2" },
                    { name: "Healing Critical Chance", value: "26.1%" },
                    { name: "Healing Critical Power", value: "173.9%" },
                ],
            },
            {
                title: "Base Stats",
                stats: [
                    { name: "Base Power", value: "7.3" },
                    { name: "Base Critical Chance", value: "3.5%" },
                    { name: "Base Critical Power", value: "173.9%" },
                    { name: "Base Penetration", value: "2%" },
                    { name: "Base Accuracy", value: "4.9%" },
                    { name: "Base Disable Duration", value: "13%" },
                    { name: "Base Block Chance", value: "5%" },
                    { name: "Base Block Mitigation", value: "10%" },
                ],
            },
            {
                title: "Physical Defense",
                stats: [
                    { name: "Armor", value: "316" },
                    { name: "Physical Mitigation", value: "24%" },
                    { name: "Physical Critical Avoidance", value: "8.2%" },
                    { name: "Physical Evasion", value: "3.8%" },
                    { name: "Physical Block Chance", value: "5%" },
                    { name: "Physical Block Mitigation", value: "10%" },
                ],
            },
            {
                title: "Magical Defense",
                stats: [
                    { name: "Magic Resist", value: "729" },
                    { name: "Magical Mitigation", value: "42.2%" },
                    { name: "Magical Critical Avoidance", value: "6.9%" },
                    { name: "Magical Evasion", value: "8%" },
                    { name: "Magical Block Chance", value: "5%" },
                    { name: "Magical Block Mitigation", value: "10%" },
                ],
            },
            {
                title: "Physical Offense",
                stats: [
                    { name: "Physical Power", value: "50.7" },
                    { name: "Physical Critical Chance", value: "11.3%" },
                    { name: "Physical Critical Power", value: "173.9%" },
                    { name: "Physical Attack Speed", value: "77.4%" },
                    { name: "Physical Penetration", value: "6%" },
                    { name: "Physical Accuracy", value: "8.9%" },
                ],
            },
            {
                title: "Magical Offense",
                stats: [
                    { name: "Magical Power", value: "356.7" },
                    { name: "Magical Critical Chance", value: "26.1%" },
                    { name: "Magical Critical Power", value: "173.9%" },
                    { name: "Magical Casting Speed", value: "97.9%" },
                    { name: "Magical Penetration", value: "10.7%" },
                    { name: "Magical Accuracy", value: "14.2%" },
                ],
            },
        ]
    };

    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const toggleAccordion = (index: number): void => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="relative min-h-screen bg-[#1e1e1e] text-[#e0e0e0]">
            <div className="pt-32 px-4 sm:px-6 md:px-12 lg:px-24">
                <div className="grid grid-cols-3 md:grid-cols-7 gap-8">
                    <div className="col-span-3 md:col-span-2 card bg-[#1c1c1c] border border-gray-700 rounded-lg shadow-lg p-2">
                        <div className="grid grid-cols-2 gap-x-4">
                            <div className="flex flex-col items-center">
                                <img src="/Information/logoantiquericard.png" alt="User Avatar" className="h-16 w-16 rounded-full" />
                                <h2 className="text-2xl text-[#ffdd57]">{profile_data.member_id}</h2>
                            </div>
                            <div className="flex flex-col">
                                <p className="font-bold text-[#a8a8a8]">ROLE: {profile_data.guild_role}</p>
                                <p className="font-bold text-[#a8a8a8]">FAMILY: {profile_data.family}</p>
                                <p className="font-bold text-[#a8a8a8]">SQUADRON: {profile_data.squadron}</p>
                                <p className="font-bold text-[#a8a8a8]">SQUADRON ROLE: {profile_data.squadron_role}</p>
                            </div>
                        </div>
                    </div>

                    {/* Columna 3 */}
                    <div className="space-y-4">
                        <div className="card bg-[#1c1c1c] text-center border border-gray-700 rounded-lg shadow-lg p-7">
                            <h2 className="text-2xl text-[#ffdd57]">DKP Squadron</h2>
                            <p className="text-lg font-bold text-[#ffaa33]">{profile_data.dkp_squadron}</p>
                        </div>
                    </div>
                    {/* Columna 4 */}
                    <div className="space-y-4">
                        <div className="card bg-[#1c1c1c] text-center border border-gray-700 rounded-lg shadow-lg p-7">
                            <h2 className="text-2xl text-[#ffdd57]">DKP Guild</h2>
                            <p className="text-lg font-bold text-[#ffaa33]">{profile_data.dkp_guild}</p>
                        </div>
                    </div>
                    {/* Columna 5 */}
                    <div className="space-y-4">
                        <div className="card bg-[#1c1c1c] text-center border border-gray-700 rounded-lg shadow-lg p-7">
                            <h2 className="text-2xl text-[#ffdd57]">Level</h2>
                            <p className="text-lg font-bold text-[#ffaa33]">{profile_data.level}</p>
                        </div>
                    </div>
                    {/* Columna 6 */}
                    <div className="space-y-4">
                        <div className="card bg-[#1c1c1c] text-center border border-gray-700 rounded-lg shadow-lg p-7">
                            <h2 className="text-2xl text-[#ffdd57]">Item lvl</h2>
                            <p className="text-lg font-bold text-[#ffaa33]">{profile_data.ilvl}</p>
                        </div>
                    </div>
                    {/* Columna 7 */}
                    <div className="space-y-4">
                        <div className="card bg-[#1c1c1c] text-center border border-gray-700 rounded-lg shadow-lg p-7">
                            <h2 className="text-2xl text-[#ffdd57]">Main Build</h2>
                            <p className="text-lg font-bold text-[#ffaa33]">{profile_data.main_archetype} + {profile_data.secondary_archetype}</p>
                        </div>
                    </div>
                </div>
            </div >

            <div className="bg-[#1e1e1e] text-[#e0e0e0] p-6 min-h-screen">
                <div className="w-1/2 bg-[#1c1c1c] border border-gray-700 p-4 rounded-lg shadow-lg">
                    <div className="flex flex-col">
                        {/* Fila principal con 4 columnas */}
                        <div className="flex w-full gap-4">
                            {/* Columna Izquierda (primeros 7 items) */}
                            <ul className="flex flex-col space-y-2 w-1/8">
                                {profile_data.items.slice(0, 7).map((item, index) => (
                                    <li key={index} className="bg-gray-700 p-2 rounded text-center hover:bg-gray-600 text-[#e0e0e0]">
                                        {item.href ? (<a href={item.href} target="_blank" rel="noreferrer">{item.name}</a>) : (item.name)}
                                    </li>
                                ))}
                            </ul>

                            {/* Columna Central-Izquierda (6 stats principales) */}
                            <div className="w-1/2 flex flex-col pt-2">
                                <h2 className="text-xl font-semibold mb-3 text-center text-[#ffdd57]">Main Build</h2>

                                <div className="grid grid-cols-2 gap-x-4">
                                    {/* Primera Columna de Stats (3) */}
                                    <ul className="space-y-2">
                                        {["Strength", "Dexterity", "Constitution"].map((statName, index) => (
                                            <li key={index} className="flex justify-between items-center text-[#e0e0e0] bg-gray-800 p-2 rounded hover:bg-gray-700">
                                                <span className="font-medium">{statName}</span>
                                                <span className="font-bold text-[#ffdd57]">
                                                    {profile_data.primary_stats.find((stat) => stat.name === statName)?.value || 0}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>

                                    {/* Segunda Columna de Stats (3) */}
                                    <ul className="space-y-2">
                                        {["Intelligence", "Wisdom", "Mentality"].map((statName, index) => (
                                            <li key={index} className="flex justify-between items-center text-[#e0e0e0] bg-gray-800 p-2 rounded hover:bg-gray-700">
                                                <span className="font-medium">{statName}</span>
                                                <span className="font-bold text-[#ffdd57]">
                                                    {profile_data.primary_stats.find((stat) => stat.name === statName)?.value || 0}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Las 4 cajas al final del contenedor */}
                                <ul className="grid grid-cols-2 gap-x-4 gap-y-2 mt-auto w-full">
                                    {profile_data.items.slice(14).map((item, index) => (
                                        <li key={index} className="bg-gray-700 p-2 rounded text-center hover:bg-gray-600 text-[#e0e0e0]">
                                            {item.href ? (<a href={item.href} target="_blank" rel="noreferrer">{item.name}</a>) : (item.name)}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Columna Derecha (items 7 a 14) */}
                            <ul className="flex flex-col space-y-2 w-1/8">
                                {profile_data.items.slice(7, 14).map((item, index) => (
                                    <li key={index} className="bg-gray-700 p-2 rounded text-center hover:bg-gray-600 text-[#e0e0e0]">
                                        {item.href ? (<a href={item.href} target="_blank" rel="noreferrer">{item.name}</a>) : (item.name)}
                                    </li>
                                ))}
                            </ul>

                            {/* Columna Central-Derecha (acordeones secondary_stats) */}
                            <div className="w-2/4">
                                {profile_data.secondary_stats.map((detail, index) => (
                                    <div key={index} className="border-t border-gray-700">
                                        <button onClick={() => toggleAccordion(index)} className="w-full text-left py-2 px-4 flex justify-between items-center text-[#e0e0e0] hover:bg-gray-800">
                                            <span className="font-medium">{detail.title}</span>
                                            <span className="transform transition-transform duration-300">
                                                {activeIndex === index ? "▲" : "▼"}
                                            </span>
                                        </button>
                                        {activeIndex === index && (
                                            <div className="bg-gray-900 text-[#a8a8a8] p-4">
                                                <ul className="space-y-1">
                                                    {detail.stats.map((stat, statIndex) => (
                                                        <li key={statIndex} className="flex justify-between items-center text-[#e0e0e0]">
                                                            <span>{stat.name}</span>
                                                            <span className="font-bold text-[#ffdd57]">{stat.value}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;