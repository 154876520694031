import React, { useState } from 'react';

interface NavBarProps {
    links: { label: string; href?: string; subLinks?: { label: string; href: string }[] }[];
}

const NavBar: React.FC<NavBarProps> = ({ links }) => {
    const [openSubMenu, setOpenSubMenu] = useState<number | null>(null);

    const toggleSubMenu = (index: number) => {
        setOpenSubMenu(openSubMenu === index ? null : index);
    };

    return (
        <ul className="flex justify-center gap-6 py-3">
            {links.map((link, index) => (
                <li key={index} className="relative text-gray-300 hover:text-white transition-colors">
                    {link.subLinks ? (
                        <>
                            <button onClick={() => toggleSubMenu(index)} className="text-base font-medium">
                                {link.label}
                            </button>
                            {openSubMenu === index && (
                                <ul className="absolute left-0 mt-2 bg-zinc-700 rounded-lg shadow-lg">
                                    {link.subLinks.map((subLink, subIndex) => (
                                        <li key={subIndex} className="px-4 py-2 hover:bg-zinc-600">
                                            <a href={subLink.href} className="block text-gray-300 hover:text-white">
                                                {subLink.label}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </>
                    ) : (
                        <a href={link.href} className="text-base font-medium">
                            {link.label}
                        </a>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default NavBar;