import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from './Logo';
import NavBar from './NavBar';


interface AppHeaderProps {
  navLinks: ({ label: string; href?: string; subLinks?: { label: string; href: string }[] })[];
  externalLinks: { label: string; href: string; className?: string }[];
  onSearch: (query: string) => void;
}

const AppHeader: React.FC<AppHeaderProps> = ({ navLinks, externalLinks, onSearch }) => {

  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="fixed top-0 left-0 z-50 w-full bg-zinc-900 bg-opacity-50 shadow-lg">
      {/* Contenedor Principal */}
      <div className="mx-auto flex h-20 items-center justify-between px-6 md:max-w-[1200px]">
        {/* Logo */}
        <Logo />
        {/* Menú de Navegación */}
        <nav className="hidden md:flex items-center">
          <div className="mx-auto md:max-w-[1200px] flex items-center">
            <NavBar links={navLinks} />
            {/* Botón de Login */}
            <a
              href="/login"
              className="ml-4 bg-[#5865f2] text-white py-2 px-4 rounded-lg hover:brightness-105 transition duration-300"
            >
              Login with Discord
            </a>
          </div>
        </nav>
        {/* Botón de menú para móviles */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Menú desplegable para móviles */}
      {isMenuOpen && (
        <nav className="md:hidden bg-zinc-700">
          <NavBar links={navLinks} />
          {/* Botón de Login para móviles */}
          <a
            href="/login"
            className="block mt-4 bg-[#5865f2] text-white py-2 px-4 rounded-lg text-center hover:brightness-105 transition duration-300"
          >
            Login with Discord
          </a>
        </nav>
      )}
    </header>
  );
};

export default AppHeader;