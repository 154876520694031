import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppHeader from './../AppHeader/AppHeader';
import ContentWrapper from './../ContentWrapper/ContentWrapper';
import Information from './../ContentWrapper/Information/Information';
import Management from '../ContentWrapper/Management/GuildManagement';
import GuildCalendar from '../ContentWrapper/Calendar/GuildCalendar';
import Profile from './../ContentWrapper/Profile/Profile';
import GuildManagement from '../ContentWrapper/Management/GuildManagement';

const App: React.FC = () => {
  const navLinks = [
    {
      label: 'Gestión', subLinks: [
        { label: 'Escuadrón', href: '/SquadronManagement' },
        { label: 'Gerencia', href: '/GuildManagement' }
      ]
    },
    {
      label: 'Calendario', subLinks: [
        { label: 'Escuadrón', href: '/SquadronCalendar' },
        { label: 'Guild', href: '/GuildCalendar' }
      ]
    },
    {
      label: 'DKPs', subLinks: [
        { label: 'Pujatorio', href: '/Pujatorio' },
        { label: 'My DKPs', href: '/MyDKPs' }
      ]
    },
    /*{
      label: 'Utilidades', subLinks: [
        { label: 'Clases', href: '/Utilidades/Clases' },
        { label: 'Profesiones', href: '/Utilidades/Profesiones' },
        { label: 'Gear', href: '/Utilidades/Gear' },
        { label: 'META', href: '/Utilidades/META' }
      ]
    },*/
    { label: 'Profile', href: '/Profile' }
  ];

  const externalLinks = [
    {
      label: 'Discord',
      href: 'https://discord.gg',
      className: 'bg-[#5865f2] text-white hover:brightness-105',
    },
  ];

  const handleSearch = (query: string) => {
    console.log('Search:', query);
  };

  return (
    <Router>
      <div className="bg-[#1e1e1e] text-white min-h-screen overflow-x-hidden">
        {/* Encabezado de la aplicación */}
        <AppHeader
          navLinks={navLinks}
          externalLinks={externalLinks}
          onSearch={handleSearch}
        />

        {/* Sección debajo de la imagen con fondo gris oscuro */}
        <div className="bg-[#1e1e1e]">
          <main>
            <div>
              <Routes>
                <Route path="/" element={<ContentWrapper />} />
                <Route path="/SquadronManagement" element={<Management />} />
                <Route path="/GuildManagement" element={<GuildManagement />} />
                <Route path="/SquadronCalendar" element={<Management />} />
                <Route path="/GuildCalendar" element={<GuildCalendar />} />
                <Route path="/Pujatorio" element={<Management />} />
                <Route path="/MyDKPs" element={<Management />} />
                <Route path="/Profile" element={<Profile />} />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </Router>
  );
};

export default App;